<template>
  <div>
    <b-modal title="가격 고정 상품 상세" size="xl" v-model="modal.goodsDetail" ok-only ok-title="닫기" ok-variant="secondary" @hide="modalHide">
      <template>
        <b-row>
          <b-col cols="12">
            <div class="p-2 title-sm">가격 고정 내역</div>
            <b-row class="p-2" v-for="history in list.histories">
              <b-col cols="1">{{history.no}}</b-col>
              <b-col>{{stateMap[history.state]}}</b-col>
              <b-col cols="5">{{history.st}} ~ {{history.ed}}</b-col>
              <b-col class="text-truncate" >{{history.name}}</b-col>
            </b-row>
          </b-col>

          <b-col class="pt-5" cols="12">
            <div class="p-2 title-sm">수정이력 확인</div>
            <div class="d-flex p-2">
              <div class="pr-3" v-for="button in list.buttons">
                <b-button class="" variant="" @click="showDiffModal(button.key, button.uniqueId, button.type)">
                  {{ button.title }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>

export default {
  name: 'PriceLimitModal',
  props: ['modal'],
  components: {},
  data() {
    return {
      diffButtonMap: {
        'mapped': {title: '수정이력 확인', type: 'diff', item: {}, db: 'secondDB', collection: 'mapped'},
        'confirmed': {title: '수정이력 확인', type: 'diff', item: {}, db: 'hub', collection: 'confirmed'},
        'gm': {title: '수정이력 확인', type: 'diff', item: {}, db: 'hub', collection: 'gm'},
        'shop': {title: '수정이력 확인', type: 'diff', item: {}, db: 'hub', collection: 'meta_shops'},
      },
      list: {
        histories: [],
        buttons: [
          {title: 'Mapped 조회', key: 'mapped', uniqueId: 'gid', type: 'string'},
          {title: '등록상품조회', key: 'confirmed', uniqueId: 'goods_no', type: 'number'},
          {title: '마스터SKU 생성/조회', key: 'gm', uniqueId: 'gm_id', type: 'string'},
          {title: 'SHOP 정보', key: 'shop', uniqueId: 'shop_id', type: 'number'},
        ]
      },
      item: {},
      item_org: {},
      busy: {},
      fields: {
        list: [
          {key: 'no', label: '가격 고정 번호', class: 'text-center'},
          {key: 'html1', label: '상태', class: 'text-center'},
          {key: 'html6', label: '적용기간', class: 'text-center'},
          {key: 'html2', label: '가격 고정 사유'},
        ],
      },
      stateMap: {proceeding: '진행중', intended: '예정', close: '종료'}
    }
  },
  async created() {

  },
  methods: {
    async showModal(item) {
      this.item = item;
      this.modal.goodsDetail = true;
      const j = await this.$api.getJson(`/price/priceLimitHistory?goods_no=${item.goods_no}`);
      if (j && j.ok) {
        this.list.histories = j.list;
      }
    },
    async showDiffModal(key, uniqueId, type) {
      if (this.item[uniqueId]) {
        const j = await this.$api.getJson(`/price/diff?filterValue=${this.item[uniqueId]}&filterKey=${uniqueId}&collection=${key}&type=${type}`);
        this.diffButtonMap[key].item = j.diff;
      }

      this.$modal.show(this.diffButtonMap[key]);
    },
    async modalHide(event) {
      this.modal.goodsDetail = false;
    },
    async goodsInfo() {

    },
  }
}
</script>
