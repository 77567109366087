<template>
  <b-tabs class="mt-3" v-model="tabIndex">
    <b-tab title="상품 조회">
      <GoodsPrice-tab v-bind="{}"></GoodsPrice-tab>
    </b-tab>
    <b-tab title="가격 고정 관리" @click="priceLimitList()">
      <GoodsPriceLimit-tab ref="priceLimitTab" v-if="loaded[1] || tabIndex === 1"></GoodsPriceLimit-tab>
    </b-tab>
  </b-tabs>
</template>

<script>
import GoodsPriceTab from './GoodsPriceTab';
import GoodsPriceLimitTab from './GoodsPriceLimitTab';

export default {
  name: 'PriceLimit',
  title: '상품별 정액 할인',
  components: {GoodsPriceTab, GoodsPriceLimitTab},
  data() {
    return {
      tabIndex: 0,
      loaded: {},
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  methods: {
    async priceLimitList() {
      this.$refs.priceLimitTab && await this.$refs.priceLimitTab.list();
    }
  },
}
</script>
