<template>
  <div>
    <b-modal title="가격 고정 상세" size="xl" v-model="modal.detail" no-enforce-focus ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <b-row class="mt-1">
        <b-col cols="2" v-if="item.no">
          <small>가격 고정 번호</small><br/>
          <div class="col-form-label">{{ item.no }}</div>
        </b-col>
        <b-col>
          <small>가격 고정 사유</small><br/>
          <b-form-input v-model="item.name" @input="pageDiff" :disabled="['','intended'].includes(this.progress) ? false : true"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="4">
          <small>시작시각</small> <small class="ml-1 text-muted">* 분단위로 입력하시면 00초에 시작합니다.</small><br/>
          <b-form-input v-model="item.st" @input="pageDiff" :disabled="['','intended'].includes(this.progress) ? false : true"></b-form-input>
        </b-col>
        <b-col cols="4">
          <small>종료시각</small> <small class="ml-1 text-muted">* 분단위로 입력하시면 00초에 종료됩니다.</small><br/>
          <b-form-input v-model="item.ed" @input="pageDiff" :disabled="['close'].includes(this.progress) ? true : false"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <small>생성시각</small><br/>
          <span class="col-form-label">{{ item._cdt }} <span v-if="item._cname">({{ item._cname }})</span></span>
        </b-col>
        <b-col cols="3">
          <small>수정시각</small><br/>
          <span class="col-form-label">{{ item._dt }}</span>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <small>비고</small><br/>
          <b-textarea v-model="item.desc" @input="pageDiff" @keyup="descValidate"></b-textarea>
        </b-col>
      </b-row>

      <div class="clearfix mt-2">
        <div class="pull-right">
          <b-button class="mr-1" variant="success" size="sm" @click="down()">Xlsx Down</b-button>
        </div>
        <b-form inline>
          <b-input-group>
            <b-form-input v-model.number="addRowNum" size="sm" class="w-65px"></b-form-input>
            <b-input-group-append>
              <b-button variant="light" size="sm" @click="addRows(addRowNum)">행 추가</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(10)">+10</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(50)">+50</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(100)">+100</b-button>
              <b-button variant="secondary" size="sm" @click="addRows(1000)">+1000</b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button class="ml-1" variant="warning" size="sm" @click="removeRows('empty')">빈 행 정리</b-button>
          <b-button class="ml-1" variant="danger" size="sm" @click="removeRows('not_done')">완전하지 않은 행 정리</b-button>
          <b-button class="ml-1" variant="info" size="sm" @click="showRows()">행 데이터</b-button>
        </b-form>
      </div>
      <div v-if="item.no">
        가격 고정 상품 수정 가능 범위를 확인해주세요.<small class="pointer fa fa-question-circle ml-1" v-b-toggle.collapseStat></small>
        <b-collapse id="collapseStat">
          <small class="ml-1 text-muted">
            * 가격 고정 종료 시<br/>
            &nbsp&nbsp - 비고<br/>
            * 가격 고정 진행 중<br/>
            &nbsp&nbsp - 가격 고정 종료 시각(현재 시각 이후로만 변경 가능)<br/>
            &nbsp&nbsp - 비고<br/>
            &nbsp&nbsp - 발란코드<br/>
            &nbsp&nbsp - 가격고정가<br/>
            &nbsp&nbsp - 파트너 할인액<br/>
            &nbsp&nbsp - 발란 할인액<br/>
            *가격 고정 진행 예정 시<br/>
            &nbsp&nbsp - 가격 고정 시작 시각 (현재 시각 이후로만 변경 가능)<br/>
            &nbsp&nbsp - 가격 고정 종료 시각 (현재 시각 이후로만 변경 가능)<br/>
            &nbsp&nbsp - 가격 고정 사유<br/>
            &nbsp&nbsp - 비고<br/>
            &nbsp&nbsp - 발란코드<br/>
            &nbsp&nbsp - 가격고정가<br/>
            &nbsp&nbsp - 파트너할인액<br/>
            &nbsp&nbsp - 발란 할인액<br/>
          </small>
        </b-collapse>
      </div>

      <hot-table class="mt-2" ref="ht" :settings="hotSettings"></hot-table>

      <hr/>

      <div class="clearfix">
        <ul>
          <li class="ml-1" style="float:left">
            유효한 최소 설정 단위는 발란코드 and 가격 고정가 and { 상품별 정액 할인 (파트너 부담) or 상품별 정액 할인 (발란 부담) } 입니다.
          </li>
          <li class="ml-1" style="float:left">
            진행중인 상품을 수정하면 가격이 순차적으로 반영됩니다.
          </li>
        </ul>
        <div class="pull-right">
          <b-badge v-if="!item.init" variant="success" class="alert-warning"><i class="fa fa-exclamation-circle"></i> 아직 최초로 저장되지 않았습니다</b-badge>
          <b-badge v-else-if="!item.saved" variant="success" class="alert-warning"><i class="fa fa-exclamation-circle"></i> 저장되지 않은 변경사항이 있습니다</b-badge>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="item._diff && item._diff.length" variant="secondary" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button v-if="item_org.init" variant="outline-danger" @click="removePage()" :disabled="busy.removePage">
          삭제
          <b-spinner class="ml-1" small v-if="busy.removePage"></b-spinner>
        </b-button>
        <b-button variant="primary" @click="ok()" :disabled="busy.savePage">
          저장
          <b-spinner class="ml-1" small v-if="busy.savePage"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>
@import '~handsontable/dist/handsontable.full.css';
</style>
<script>
import {HotTable} from '@handsontable/vue';
import Handsontable from 'handsontable';
import {down} from '@/shared/impexp'

export default {
  name: 'PriceLimitModal',
  props: ['modal', 'shop', 'shopMap', 'list'],
  components: {HotTable},
  data() {
    return {
      item: {},
      item_org: {},
      cfMap: {},
      addRowNum: 1,
      busy: {goodsInfo: false, savePage: false, removePage: false},
      progress: '',
      hotSettings: {
        data: [],
        colHeaders: ['발란코드', '가격 고정가', '상품별 정액 할인(파트너 부담)', '상품별 정액 할인(발란 부담)', '<span class="text-muted">상품명</span>',
          '<span class="text-muted">발란회원가</span>',
          '<span class="text-muted">발란추천가 목표 차액</span>',
          '<span class="text-muted">단독 B최저가 목표 차액</span>'
        ],
        columns: [
          {data: 'goods_no', type: 'numeric'},
          {data: 'priceLimit', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          {data: 'initPartnerAddAmount', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          {data: 'initBalaanAddAmount', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}},
          {data: 'info', renderer: 'html', readOnly: true},
          {data: 'price', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, readOnly: true},
          {data: 'diffBalaanPrice', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, readOnly: true},
          {data: 'diffBMinPrice', type: 'numeric', numericFormat: {pattern: '0,0', culture: 'ko-KR'}, readOnly: true},
        ],
        cells: (row, col, prop) => {
          let className = {
            goods_no: 'htCenter',
            info: 'htLeft',
            epPrice: 'htRight text-muted',
            diffBalaanPrice: 'htRight text-muted',
            diffBMinPrice: 'htRight text-muted'
          }[prop] || 'htRight';
          return className ? {className} : {};
        },
        colWidths: [75, 120, 200, 180, 360, 90, 120, 100],
        rowHeaders: true,
        height: 500,
        afterChange: async (changes) => {
          let changedGoodsNoRows = changes && changes.filter(([row, prop, oldValue, newValue]) => {
            if (['initPartnerAddAmount', 'initBalaanAddAmount'].includes(prop) && newValue > 0) {
              const obj = this.item.goods[row];
              obj[prop] = obj[prop] * -1;
            }
            return prop === 'goods_no' && oldValue + '' !== newValue + '';
          }).map(e => e[0]) || [];
          if (changedGoodsNoRows.length) { // goods_no 자체가 바뀌었다면 info, 가격 등을 갱신해야 한다.
            changedGoodsNoRows.forEach(row => {
              const obj = this.item.goods[row];
              delete obj.info;
              delete obj.epPrice;
              delete obj.diffBalaanPrice;
              delete obj.diffBMinPrice;
            });
            await this.goodsInfo();
          }
          this.$refs.ht.hotInstance.render();
          this.pageDiff();
        },
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        licenseKey: 'non-commercial-and-evaluation',
      },
      hasStockRenderer(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.style.backgroundColor = 'yellow';
      },
    }
  },
  async created() {

  },
  methods: {
    async showModal(row) {
      let dItem = {};
      this.cfMap = {};
      if (!row) { // 새 페이지 생성
        dItem = {
          item: {
            name: '', desc: '', goods: [{}],
            st: this.$utils.kstDHM(this.$moment().add(1, 'day').startOf('hour')),
            ed: this.$utils.kstDHM(this.$moment().add(2, 'day').startOf('hour')),
            spread: 1, init: false, saved: true
          }
        };
      } else {
        dItem = await this.$api.getJson(`/price/priceLimitDetail?no=${row.item.no}`);
        dItem.item.init = true;
        dItem.item.saved = true;
      }

      this.coupons = await this.$api.getMeta('coupon');
      this.shop_exclude = await this.$api.postMeta({type: 'shop', meta: [{name: 'shop',query: {$or: [{use_yn: 'y',
            $where: 'this.sale_base != this.supply_base'},{is_exclude_priceLimit: true}]} ,projection: {'shop_id': 1 , _id: 0}}]});
      this.item_org = dItem.item;
      this.item = this.$utils.clone(dItem.item);
      const dhm = this.$utils.kstDHM();
      const org = this.item_org;
      this.progress = org.ed <= dhm ? 'close' : org.st >= dhm ? 'intended' : 'proceeding';
      this.hotSettings.columns.forEach(e => {
        if (['goods_no','priceLimit','initPartnerAddAmount','initBalaanAddAmount'].includes(e.data)) {
          e.readOnly = this.progress === 'close' ? true : false;
        }
      });
      await this.goodsInfo();
      this.modal.detail = true;
      this.renderTable();
    },
    showDiffModal(item) {
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, db: 'hub', collection: 'price_limit'});
    },
    async modalHide(event) {
      if (event.trigger === 'ok') {
        event.preventDefault && event.preventDefault();
        if (this.item.saved){
          this.modal.detail = false;
          this.list();
        } else {
          this.savePage();
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        this.pageDiff();
        if (!this.item.saved) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault && event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async goodsInfo() {
      // 상품정보가 없는 상품을 db 에서 가져온다.
      if (this.busy.goodsInfo) return;
      const new_goods_no = this.item.goods.filter(e => e.goods_no).map(e => e.goods_no);
      if (new_goods_no.length) {
        this.busy.goodsInfo = true;
        let j = await this.$api.postJson('/price/priceLimitGoods', {minimal: 1, goods_no_include: new_goods_no, searchCol: {
            gm: 1, price_table: 1
          }, coupons: this.coupons.coupon, shop_exclude: this.shop_exclude.shop, limit: 10000});
        this.busy.goodsInfo = false;
        if (j) {
          j.list.forEach(e => this.cfMap[e.goods_no] = e);
        }
      }
      for (let g of this.item.goods) {
        if (g.goods_no && !g.info) {
          let cf = this.cfMap[g.goods_no];
          if (cf) {
            Object.assign(g, {
              info: `<a href="/#/shop/${cf.shop_id}" target="_blank" class="badge badge-success mr-1">${cf.shop_id}. ${cf.shop}</a>` +
                `<a href="/#/goods/${cf.goods_no}" target="_blank" class="badge badge-${cf.tot_stock > 0 ? 'primary' : 'secondary'}">${cf.goods_no}</a> ` +
                `${cf.goods_nm} (재고: ${cf.tot_stock})`,
              valid: true,
            }, cf);
            g.epPrice = cf.price - cf.discountAmount;
            g.diffBalaanPrice = cf.minPriceAllAmount < 0 ? cf.minPriceAllAmount : 0;
            g.diffBMinPrice = cf.bMinPriceAmount < -10 ? cf.bMinPriceAmount : 0;
          } else {
            Object.assign(g, {
              info: `<span class="text-muted text-danger">상품정보를 등록상품(confirmed)에서 찾지 못했습니다</span>`, valid: false
            });
          }
        }
      }
    },
    renderTable() {
      setTimeout(_ => {
        let instance = this.$refs.ht.hotInstance;
        instance.loadData(this.item.goods);
        instance.render();
        window.ht = instance;
      });
    },
    addRows(n) {
      let instance = this.$refs.ht.hotInstance;
      for (let i = 0; i < n; i++) {
        this.item.goods.push({});
      }
      instance.loadData(this.item.goods);
      instance.render();
    },
    showRows() {
      this.$modal.show({title: '가격 설정 데이터', item: this.item.goods, type: 'json'});
    },
    removeRows(type) {
      let page = this.item;
      let instance = this.$refs.ht.hotInstance;
      if (type === 'empty') {
        const goodsCols = 'goods_no,priceLimit,initPartnerAddAmount,initBalaanAddAmount'.split(',');
        page.goods = page.goods.filter(e => !goodsCols.every(k => e[k] == null || e[k] === ''));
        this.$alertTop(`빈 행을 정리했습니다`, {variants: 'warning'});
      } else if (type === 'not_done') {
        page.goods = page.goods.filter(e => e.goods_no && e.priceLimit && (e.initPartnerAddAmount || e.initBalaanAddAmount) != null);
        this.$alertTop(`완전하지 않은 행을 정리했습니다`, {variants: 'danger'});
      }
      instance.loadData(this.item.goods);
      instance.render();
    },
    descValidate(){
      if (this.item.desc.length > 100 || this.item.desc.trim().length > 80) {
        this.item.desc = this.item.desc.slice(0,80);
        alert('최대 글자수는 공백 포함 100자 or 공백 제외 80 이내 입니다.');
      }
    },
    pageDiff() {
      let diff = {};
      const cols = 'name,st,ed,desc'.split(',');
      const goodsCols = 'goods_no,priceLimit,initPartnerAddAmount,initBalaanAddAmount'.split(',');
      for (let c of cols) {
        if (this.item[c] !== this.item_org[c]) diff[c] = true;
      }
      let _str = this.item.goods.filter(e => (e.goods_no || e.priceLimit || e.initPartnerAddAmount || e.initBalaanAddAmount) != null)
          .sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');
      let _orgstr = this.item_org.goods.filter(e => (e.goods_no || e.priceLimit || e.initPartnerAddAmount || e.initBalaanAddAmount) != null)
          .sort((a, b) => a.goods_no - b.goods_no).map(e => goodsCols.map(c => e[c]).join('')).join('');
      if (_str !== _orgstr) diff.goods = true;

      this.item.saved = !Object.keys(diff).length;
    },
    async savePage() {
      const dhm = this.$utils.kstDHM();
      const page = this.item;
      let nan = [], low = [], lastZero = [];
      let goods = page.goods.filter(e => e.valid && e.goods_no && e.priceLimit && (e.initPartnerAddAmount || e.initBalaanAddAmount) != null).map(e => {
        let obj = {}, keys = 'goods_no,priceLimit,initPartnerAddAmount,initBalaanAddAmount'.split(',');
        for (let [k, v] of Object.entries(e).filter(([k, v]) => ~keys.indexOf(k))) {
          if (typeof (v) === 'string' && isNaN(v.replace(/,/g, ''))) {
            nan.push(v);
            continue;
          }
          if (typeof (v) === 'string') v = v.trim();
          if (v != null && v !== '') {
            obj[k] = (typeof (v) === 'string' ? +v.replace(/,/g, '') : v);
            if (obj[k] < 10000 && 'priceLimit'.split(',').includes(k)) {
              low.push(obj.goods_no);
            }
            if (obj[k] % 10 != 0 && 'priceLimit,initPartnerAddAmount,initBalaanAddAmount'.split(',').includes(k)) {
              lastZero.push(obj[k]);
              continue;
            }
          }
        }

        return obj;
      });

      if (nan.length) return alert('다음 항목들이 숫자가 아닙니다.\n' + nan.join('\n'));
      if (low.length) return alert('다음 상품들의 가격이 10000원 미만입니다.\n' + low.join('\n'));
      if (lastZero.length) return alert('다음 항목의 설정 가격이 10단위가 아닙니다.\n' + lastZero.join('\n'));
      if (!goods.length) return alert('유효한 설정 항목이 없습니다');

      const goodsMulti = this.$utils.arr2multi(goods, 'goods_no');
      const dupGoods = Object.values(goodsMulti).filter(e => e.length > 1);
      if (dupGoods.length) return alert(`리스트 내 중복된 발란코드가 존재합니다 : \n${dupGoods.map(e => e[0].goods_no).join('\n')}`);

      if (!page.name.trim()) return alert('가격 고정 사유 입력해주세요');
      if (!page.st.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) return alert('시작시각을 YYYY-MM-DD HH:mm 형태로 입력해주세요');
      if (this.$moment(page.st).toDate().toString() === 'Invalid Date') return alert('시작시각의 값이 정상적인 시각이 아닙니다.');
      if (this.$moment(page.st).minute() % 10 !== 0) return alert('시작 시각이 10분 단위가 아닙니다.');
      if (!page.ed.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) return alert('종료시각을 YYYY-MM-DD HH:mm 형태로 입력해주세요');
      if (this.$moment(page.ed).toDate().toString() === 'Invalid Date') return alert('종료시각의 값이 정상적인 시각이 아닙니다.');
      if (this.$moment(page.ed).minute() % 10 !== 0) return alert('종료 시각이 10분 단위가 아닙니다.');
      if (page.ed < page.st) return alert('시작시각이 종료시각보다 미래입니다');
      if (this.progress === 'intended' && page.st < dhm) return alert('시작 시각은 현재 시각 이후로만 변경 가능합니다.');
      if (this.progress !== 'close' && page.ed < dhm) return alert('종료 시각은 현재 시각 이후로만 변경 가능합니다.');

      this.busy.savePage = true;
      let j = await this.$api.postJson('/price/checkPriceLimit', {...page, goods});
      this.busy.savePage = false;
      if (j) {
        // 중복여부 안내
        const dupArrs = Object.values(j.dupMap);
        const goodsMap = this.$utils.arr2map(page.goods, 'goods_no', 'price');
        if (dupArrs.length) return alert(`${dupArrs.length} 건의 상품이 이전 가격설정에 존재합니다.` +
          `\n${dupArrs.map(arr => `${arr[0].goods_no} - 현재 설정 가격: ${goodsMap[arr[0].goods_no]}\n${
            arr.map(e => `  #${e.no} ${e.name}, 파트너 분담액: ${e.initPartnerAddAmount}, 발란 분담액: ${e.initBalaanAddAmount}, 종료: ${e.ed}`).join('\n')
          }`).join('\n')}` +
          `\n일자가 겹치지 않게 조절하거나 기존 설정을 제거후 다시 시도해주세요`);

        // goods_no 와 priceLimit,initPartnerAddAmount,initBalaanAddAmount가 있어야 최소조건이다.
        if (goods.length !== page.goods.length && !confirm(`${page.goods.length - goods.length} 개의 유효하지 않은 행이 존재합니다. 제외하고 저장하시겠습니까?`)) return;

        const shop_ids = this.$utils.set(goods.map(e => this.cfMap[e.goods_no].shop_id));
        delete page.shop_id;

        this.busy.savePage = true;
        j = await this.$api.postJson('/price/savePriceLimit', {...page, shop_ids, goods});
        this.busy.savePage = false;
        if (j) {
          this.$alertTop('저장되었습니다');
          this.modal.detail = false;
          this.list();
        }
      }
    },
    async removePage() {
      const org = this.item_org;
      if (this.progress !== 'intended') {
        alert(`[${org.name}] 페이지는 삭제할 수 없습니다. 진행 예정건만 삭제 가능합니다.`);
        return;
      }
      if (!confirm(`[${org.name}] 페이지를 삭제하시겠습니까?`)) return;
      if (org.init === false) {
        this.$alertTop('삭제되었습니다');
        this.modal.detail = false;
        return;
      }
      this.busy.removePage = true;
      let j = await this.$api.postJson('/price/removePriceLimit', {no: this.item.no});
      this.busy.removePage = false;
      if (j) {
        this.$alertTop('삭제되었습니다');
        // parent의 items 에서 제거한다.
        this.modal.detail = false;
        this.list();
      }
    },
    async down() {
      const header = '발란코드,가격 고정가,상품별 정액할인(파트너 부담),상품별 정액 할인(발란 부담),SHOP ID,상품명,재고,발란회원가,발란추천가 목표 차액,단독 B최저가 목표 차액'.split(',');
      const fields = 'goods_no,priceLimit,initPartnerAddAmount,initBalaanAddAmount,shop_id,goods_nm,tot_stock,price,diffBalaanPrice,diffBMinPrice'.split(',');
      down(this.item.goods, header, fields, `가격고정관리_${this.$utils.kstDT()}`, 'xlsx');
    },
  }
}
</script>
