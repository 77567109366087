<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="가격 고정 번호, 가격 고정 사유를 입력해주세요." v-model="form.list.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                      v-focus></b-form-input>
      </b-input-group>
      <shop-preset v-model="form.list.shop"></shop-preset>
      <b-row>
        <b-col>
          <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
            <template v-for="(f) in statusOptions">
              <div class="flex-grow-0 mb-1 mr-3" :key="f.key">
                <div><small class="mb-n2">{{ f.name }}</small></div>
                <b-btn-group class="d-block">
                  <b-btn v-for="o in f.options" size="sm" :key="o.value"
                         :variant="form.list[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                         @click="o.click ? o.click() : form.list[f.key] = o.value">
                    {{ o.text }}
                  </b-btn>
                </b-btn-group>
              </div>
            </template>
            <b-col md="2">
              <small>발란코드</small>
              <b-form-textarea :rows="2" v-model="form.list.goods_no_include" placeholder="포함할 발란코드"></b-form-textarea>
            </b-col>
          </div>
        </b-col>
      </b-row>
      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm">초기화</b-button>
      </div>
    </b-card>
    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage.list" :isBusy="busy.list" :getMoreBusy="busy.listmore"
             :hasMore="hasMore.list"
             :caption="items.list.length + ' 개'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>

    <price-limit-modal ref="modal" v-bind="{modal, shop, shopMap, list}"></price-limit-modal>
  </div>
</template>

<script>
import PriceLimitModal from '@/views/price/PriceLimit/PriceLimitModal.vue';

export default {
  name: 'GoodsPriceLimitTab',
  title: '가격 고정 관리',
  components: {PriceLimitModal},
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          shop: [],
          skip: 0,
          orderBy: 'no',
          goods_no_include: '',
          progress: 'ALL',
          sortOrder: 'no'
        },
      },
      form: {list: {}},
      shopMap: {},
      items: {list: []},
      perPage: {list: 50},
      modal: {detail: false, dupGoods: false},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      shop: [],
      statusOptions: [
          {
            name: '상태', key: 'progress', options: [
              {text: '전체', value: 'ALL'},
              {text: '진행중', value: 'proceeding', variant: 'success'},
              {text: '예정', value: 'intended', variant: 'warning'},
              {text: '종료', value: 'close', variant: 'secondary'},
            ]
          },
          {
            name: '정렬순서', key: 'sortOrder', options: [
              {text: '최신순', value: 'no', variant: 'primary'},
              {text: '시작시각', value: 'st', variant: 'primary'},
              {text: '종료시각', value: 'ed', variant: 'primary'},
              {text: '최종수정시각', value: '_dt', variant: 'primary'},
            ]
          }
        ],
      fields: {
        list: [
          'selected',
          {key: 'no', label: '가격 고정 번호', class: 'text-center'},
          {key: 'html5', label: '상태', class: 'text-center'},
          {key: 'html7', label: '가격 고정 사유'},
          {key: 'html6', label: '적용기간', class: 'text-center'},
          {key: 'html3', label: '상품수', class: 'text-center', style: {maxWidth: '100px'}},
          {key: 'html4', label: '생성시각', class: 'text-center', style: {maxWidth: '140px'}},
          {key: 'html1', label: '비고'},
          {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
        ],
      },
      labelMap: {all: '전체', proceeding: '진행중', intended: '예정', close: '종료'},
      variantMap: {all: 'light', proceeding: 'success', intended: 'warning', close: 'secondary'},
    }
  },
  async created() {
    this.form = this.$utils.clone(this.defaultForm);
    await this.$api.setMeta(this, 'shop');
    await this.list();
    //this.checkDupGoods();
  },
  methods: {
    async list (more) {
      let form = this.form.list;
      let inc = form.goods_no_include;
      inc = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      inc = [...inc].map(e => e.split(/\D+/g)).flat().map(e => +e); // 숫자가 아닌 문자 처리
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let j = await this.$api.postTable(this, '/price/priceLimit', {...form, shop, inc}, {more, fnAssign: this.assignTableData});
      j || alert('system error');
    },
    assignTableData (e) {
      const dhm = this.$utils.kstDHM();
      const $m = this.$moment;
      const durationStr = $m().add($m.duration($m(e.ed, 'YYYY-MM-DD HH:mm:ss').diff($m(e.st, 'YYYY-MM-DD HH:mm:ss'))).asHours(), 'hour').fromNow().replace('이내', '동안');
      e.progress = e.ed <= dhm ? 'close' : e.st >= dhm ? 'intended' : 'proceeding';
      // shop 갯수 표현
      if (e.shop_id) {
        e.shop_name = (this.shopMap[e.shop_id] || {}).boutique || '';
        e.shop_badges = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success mr-1">${e.shop_id}. ${e.shop_name}</a>`;
      } else if (e.shop_ids) {
        if (e.shop_ids.length <= 2) {
          e.shop_badges = e.shop_ids.map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}. ${(this.shopMap[shop_id] || {}).boutique || ''}</a>`).join('');
        } else {
          e.shop_badges = e.shop_ids.slice(0, 5).map(shop_id => `<a href="/#/shop/${shop_id}" target="_blank" class="badge badge-success mr-1">${shop_id}</a>`).join('');
          if (e.shop_ids.length > 5) {
            e.shop_badges += `<span class="badge badge-success mr-1">외 ${e.shop_ids.length - 5} 개 SHOP</span>`;
          }
        }
      }

      const edStr = e.progress === 'intended' ? $m(e.ed).fromNow().replace('이내', '뒤 종료') :
          e.progress === 'close' ? $m(e.ed).fromNow().replace('전', '전 종료') :
              $m(e.st).fromNow().replace('이내', '뒤 시작');

      e.html1 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop_name}</a>`;
      e.html3 = e.goodsCnt;
      e.html4 = `${e._cdt}<br/><span class="text-muted">(${this.$moment(e._cdt).fromNow()}${e._name ? ', ' + e._name : ''})</span>`;
      e.html5 = `<span class="badge badge-${this.variantMap[e.progress]}">${this.labelMap[e.progress]}</span>`;
      e.html6 = `${e.st} ~ ${e.ed}<br/><span class="text-muted">(${durationStr}, ${edStr})</span>`;
      e.html7 = `<div class="text-truncate" style="width:250px;">${e.shop_badges ? e.shop_badges + '<br/>' : ''}${e.name}</div>`;
      e.html1 = `<div class="text-truncate" style="width:300px;">${e.desc ? e.desc : ''}</div>`;
    },
    resetForm() {
      this.form = this.$utils.clone(this.defaultForm);
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.$refs.modal.showModal(row);
      }
    },
  }
}

</script>
